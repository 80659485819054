<template>
  <v-container fluid class="mb-10">
    <v-row class="ml-7" style="width: 96%">
      <v-col cols="12">
        <v-card>
          <v-col cols="12">
            <v-card class="ml-10 mr-10">
              <v-data-table
                :headers="headers"
                :items="desserts"
                class="elevation-1"
                style="background: #f5f5f5"
                :server-items-length="totalItems1" 
                hide-default-footer
              >
                <template v-slot:top>
                  <v-toolbar elevation="2" style="background: #f5f5f5">
                    <v-toolbar-title
                      style="margin-left: -14px"
                      class="mt-2 mb-2 ml-2"
                      >All Coupons</v-toolbar-title
                    >

                    <v-spacer></v-spacer>
                    <v-menu
                      :close-on-content-click="false"
                      v-model="dialog"
                      scrollable
                      transition="scale-transition"
                      origin="top right"
                      :nudge-left="220"
                      max-width="420px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          dark
                          class="ma-2"
                          v-bind="attrs"
                          v-on="on"
                          elevation="0"
                        >
                          <v-icon>mdi-plus</v-icon>
                          Add Coupon
                        </v-btn>
                      </template>

                      <v-card class="my-0">
                        <v-container>
                          <v-card-text>
                            <v-row>
                              <v-col cols="12">
                                <v-text-field
                                  dense
                                  v-model="editedItem.cupon_code"
                                  outlined
                                  label="Coupon Code"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12">
                                <v-text-field
                                  type="number"
                                  dense
                                  v-model.number="editedItem.amount"
                                  outlined
                                  label="Discount Percentage"
                                ></v-text-field>
                              </v-col>

                              <v-col cols="12">
                                <v-select
                                  dense
                                  v-model="editedItem.is_active"
                                  :items="select_val"
                                  label="Active Status"
                                  outlined
                                ></v-select>
                              </v-col>

                              <v-col cols="12">
                                <v-menu
                                  v-model="menu"
                                  :close-on-content-click="false"
                                  :nudge-right="0"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="290px"
                                  class="ma-0"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="editedItem.start_from"
                                      label="Start Date"
                                      prepend-icon="mdi-calendar"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="editedItem.start_from"
                                    @input="menu = false"
                                    class="ma-0"
                                  ></v-date-picker>
                                </v-menu>
                              </v-col>

                              <v-col cols="12">
                                <v-menu
                                  v-model="menu2"
                                  :close-on-content-click="false"
                                  :nudge-right="0"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="290px"
                                  class="ma-0"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="editedItem.valid_to"
                                      label="End Date"
                                      prepend-icon="mdi-calendar"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="editedItem.valid_to"
                                    @input="menu2 = false"
                                    class="ma-0"
                                  ></v-date-picker>
                                </v-menu>
                              </v-col>
                            </v-row>
                          </v-card-text>
                          <v-card-actions class="">
                            <v-spacer></v-spacer>
                            <v-btn color="red darken-1" text @click="close">
                              Cancel
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              @click="save"
                              elevation="0"
                              :disabled="
                                !editedItem.cupon_code ||
                                typeof editedItem.amount != 'number' ||
                                editedItem.amount < 0 ||
                                !editedItem.start_from ||
                                !editedItem.valid_to
                              "
                            >
                              Save
                            </v-btn>
                          </v-card-actions>
                        </v-container>
                      </v-card>
                    </v-menu>
                  </v-toolbar>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                  </v-icon>
                  <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
                </template>
              </v-data-table>

                <div class="d-flex justify-content-center mt-5">
                  <!-- <v-col cols="3" md="3"> -->
                  <div class="col-4">
                      Total {{ totalItems1 }} records
                  </div>
                  <!-- </v-col> -->
                  <!-- <v-col cols="3" md="3"> -->
                  <div class="col-4">
                      <v-select dense outlined hide-details v-model="perPage1" label="Items per page" @change="handlePageSizeChange" :items="pageSizes1" >
                      </v-select>                                                
                  </div>
                  <!-- </v-col> -->
                  <!-- <v-col cols="6" md="3" > -->
                  <div class="col-4">
                      <v-pagination v-model="currentPage1" :length="totalPages1" total-visible="7" @input="handlePageChange">
                      </v-pagination>
                  </div>
                  <!-- </v-col> -->
                </div>
            </v-card>
          </v-col>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" centered :color="color">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>


<script>
export default {
  data: () => ({
    menu: false,
    menu2: false,
    perPage1: '10',
    currentPage1: 1,
    totalItems1: 0,
    totalPages1: 0,
    pageSizes1: ['10', '30', '50', '100', 'All'],
    dialog: false,
    snackbar: false,
    select_val: [true, false],
    headers: [
      { text: "Coupon Code", value: "cupon_code", sortable: false },
      { text: "Discount (%)", value: "amount" },
      { text: "Start Date", value: "start_from" },
      { text: "End Date", value: "valid_to" },
      { text: "Active Status", value: "is_active", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    desserts: [],
    contact: {},
    editedIndex: -1,
    editedItem: {
      cupon_code: "",
      amount: "",
      is_active: true,
    },
    defaultItem: {
      cupon_code: "",
      amount: 0,
      is_active: "",
    },
    color: "",
    text: "",
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },

  methods: {

    handlePageSizeChange(event) {
      this.perPage1 = (event);
      this.loading = true;
      this.initialize();
     },

    handlePageChange(value) {
      this.currentPage1 = value;
      this.loading = true;
      this.initialize();
      window.scrollTo(0, 0);
    },
    initialize() {
      let perPage ;
      if (this.perPage1 === 'All'){
          perPage = this.totalItems1;
      }
      else
      {
          perPage = this.perPage1;
      }
      this.loading = true;
      axios.get(`productdetails/all_cupon?page=${this.currentPage1}&size=${perPage}`).then((response) => {
        if (response.data.success && response.data.data.length) {
          this.desserts = response.data.results.reverse();
          this.totalItems1 = response.data.count;
          this.totalPages1 = parseInt(this.totalItems1 / this.perPage1);
          this.loading = false;

        }
      });
    },

    onGivenStartDate(date) {
      this.editedItem.start = date;
    },

    onGivenEndDate(date) {
      this.editedItem.end = date;
    },

    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      if (confirm("Do you really want to delete?")) {
        axios
          .post(`productdetails/delete_cupon/${item.id}/`)
          .then((response) => {
            this.desserts = this.desserts.filter(
              (contact) => contact.id !== item.id
            );
          });
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        let index = this.editedIndex;
        axios
          .post(
            `productdetails/update_cupon/${this.editedItem.id}/`,
            this.editedItem
          )
          .then((response) => {
            Object.assign(this.desserts[index], response.data);
            window.location.reload(true);
          });
      } else {
        axios
          .post("productdetails/create_cupon/", this.editedItem)
          .then((response) => {
            this.text = response.data.message;
            this.color = "success";
            this.snackbar = true;
            this.$router.push("/dashboard/pages/coupon");
          });
        this.desserts.unshift(this.editedItem);
      }
      this.close();
    },
  },
};
</script>


<style>
.v-sheet--offset {
  top: -10px;
  position: relative;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.19rem;
  height: 48px;
}
</style>

